import 'core-js/stable';
import 'regenerator-runtime/runtime';

require('chosen-js');

/**
 * Expose jQuery globally under the window object for RSpec & Development Only
 * Note: Webpack 4 has only Production & Development environments
 */
// eslint-disable-next-line no-process-env
if (process.env.NODE_ENV === 'development') {
  global.$ = global.jQuery = $;
}

import * as Bootstraping from '@app/features/bootstraping';
import * as Chosen from '@app/features/apply_chosen';
import * as CountryDependent from '@app/features/country_dependent_fields';
import * as CreditCard from '@app/features/populate_card';
import * as Lang from '@app/features/language_select';
import * as Locale from '@app/features/default_locale';
import * as OldIE from '@app/features/ie_scripts';
import * as OnlineBanking from '@app/features/online_banking';
import * as PaymentSelect from '@app/features/payment_type_select';
import * as Security from '@app/features/anti_clickjack';
import * as ShippingAddress from '@app/features/shipping_support';
import * as Tokenization from '@app/features/handle_tokenization';

import EzeecardPayout from '@app/features/ezeecard_payout_custom_attributes';
import Pay from '@app/features/pay';
import PayLater from '@app/features/pay_later';
import ThreedsMethod from '@app/features/threeds/v2/threeds_method';
import WebPaymentReact from '@app/react.js';
import {
  listenForCardInputChange as installmentsListenForCardInputChange
} from '@app/features/installments';

/*
 * Protection against “Clickjacking”
 * (which is a subset of “UI redressing”)
 */
Security.fakeAntiClickJack();

Locale.setDefaultLocale();

WebPaymentReact.initialize();

$(document).ready(function() {
  Chosen.applyChosenToSelectBoxes();
  Lang.listenForLanguageChange();

  EzeecardPayout.cacheIssuingBinsOnce();
  Bootstraping.disableAllTransactionTypeForms();
  PaymentSelect.handleTransactionTypeSelect();
  PaymentSelect.handlePaymentMethodCategorySelect();
  PaymentSelect.handlePaymentTypeSelect();
  PaymentSelect.handleBankCodesSelect();
  PaymentSelect.handleAuthCodeInput();
  PaymentSelect.listenForResize();
  PayLater.listenForClick();

  OldIE.handleIeSpecificScripts();

  Bootstraping.loadLogosImages();

  CreditCard.listenForCardInputChange();
  CountryDependent.listenForCountryChange();

  Tokenization.listenForTokenMappingSelection();
  Tokenization.listenForTokenInvalidate();
  Tokenization.listenForTokenMarkDefault();

  OnlineBanking.handlePaymentTypes();

  ShippingAddress.listenForShippingAddressCheckbox();
  ShippingAddress.listenForShippingCountryChange();

  Pay.listenForPaymentSubmission();
  ThreedsMethod.performThreedsMethod();

  installmentsListenForCardInputChange();
});

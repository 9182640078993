import { animateTransactionTypeContainer } from './payment_type_select';
import { exists } from '@app/lib/utility/exists';

// Delay in milliseconds for user input debounce
const USER_INPUT_DELAY = 800;
// Delay in milliseconds for animations
const ANIMATION_DELAY = 100;
// ID of the box indicating eligibility for installments
const ELIGIBLE_BOX_ID = 'eligible-for-installments';
// Supported card brands for installments
const SUPPORT_CARD_BRANDS = ['visa'];
// ID for Visa installment card logo
const VISA_INSTALLMENT_CARD_LOGO_ID = 'visa-installment-card-logo';
// Class used to hide element from view
const HIDDEN = 'hidden';

const Installments = (() => {
  // Stores terminal-related data
  let terminalData = null;

  // Timeout for debouncing user input
  let userInputTimeout = null;

  // Map of card brands and their associated logo IDs
  const cardBrandsLogoMap = [
    {
      cardBrand: 'visa',
      id: VISA_INSTALLMENT_CARD_LOGO_ID
    }
  ];

  // Template for rendering installment plan options
  /* eslint-disable max-len */
  const installmentPlanTemplate = (plan) => '<div class="installment-plan">' +
             `<input id="installment-plan-${plan.installments}" type="radio" value="${plan.id}" name="wpf_form[installment_plan_id]">` +
             '<div class="installment-plan-item">' +
                '<div class="installment-plan-body">' +
                  `<label class="installment-select" for="installment-plan-${plan.installments}">` +
                    `<div>${plan.name}</div>` +
                    '<div class="installment-info">' +
                      `<strong>${I18n.t('javascript.installments.plan_attribute.recurring_cost', { recurring_cost: plan.recurringCost, frequency: plan.frequency.toLowerCase() })}</strong>` +
                      `<small>${I18n.t('javascript.installments.plan_attribute.recurring_fees', { recurring_fee: plan.recurringFee, frequency: plan.frequency.toLowerCase(), annual_percentage_rate: plan.annualPercentageRate })}</small>` +
                      `<small>${I18n.t('javascript.installments.plan_attribute.total', { total_plan_cost: plan.totalPlanCost, total_fees: plan.totalFees })}</small>` +
                    '</div>' +
                  '</label>' +
                  '<div class="installment-logo visa-installment-logo"></div>' +
                '</div>' +
                '<div class="installment-plan-tac">' +
                  '<input name="wpf_form[installment_plan_tac]" type="hidden" value="0" autocomplete="off" disabled="disabled">' +
                  `<input id="installment-plan-tac-${plan.installments}" type="checkbox" value="1" name="wpf_form[installment_plan_tac]" disabled="disabled">` +
                  `<label for="installment-plan-tac-${plan.installments}">` +
                    `<span>${plan.termsAndConditions.text}</span>` +
                    `<a href="${plan.termsAndConditions.url}" target="_blank" rel="nofollow">${plan.termsAndConditions.url}</a>` +
                  '</label>' +
                '</div>' +
             '</div>' +
           '</div>';
  /* eslint-enable max-len */

  // Validates card number
  const isCardNumberValid = (number) => window.Payment.fns.validateCardNumber(number);

  // Triggers animation for transaction container
  const animateContainer = () =>
    setTimeout(() => animateTransactionTypeContainer(), ANIMATION_DELAY);

  // Sets up click event listeners for UI interactions related to installment selection
  const handleUiInteractions = () => {
    $('.installment-select').on('click', (event) => {
      animateContainer();
      $('input[name="wpf_form[installment_plan_tac]"]').prop('checked', false);
      // Disable all installment tac inputs
      $('.installment-plan-tac input').prop('disabled', true);
      // Enable only the selected plan's tac inputs
      $(event.currentTarget)
        .closest('.installment-plan-item')
        .find('.installment-plan-tac input')
        .prop('disabled', false);
    });
    // Ensure TAC inputs for hidden installment plans are disabled
    $('.installment-plan-tac:hidden input').prop('disabled', true);
  };

  // Removes UI interaction listeners
  const stopHandleUiInteractions = () => {
    $('.installment-select').off('click');
  };

  // Adds installment plan options to the DOM
  const addInstallmentPlanOptionsToDom = (selector, plans) => {
    const plansHtml = plans.reduce((result, plan) =>
      [...result, installmentPlanTemplate(plan)], []
    ).join('\n');

    selector.after(plansHtml);
  };

  // Toggles eligibility box visibility based on conditions
  const eligibleForInstallments = (show) => {
    const $cardNumberField = $('#wpf_form_card_number');
    const $eligibleBox = $(`#${ELIGIBLE_BOX_ID}`);

    if (show) {
      if (!exists($eligibleBox)) {
        const eligibleHtml = $('<span/>', {
          html: I18n.t('javascript.installments.eligible')
        });

        const eligible = $('<div/>', {
          id: ELIGIBLE_BOX_ID,
          html: eligibleHtml
        });

        $cardNumberField.after(eligible);
      }
    } else {
      $eligibleBox.remove();
    }
  };

  // Updates the installment plan selection UI based on available plans
  const toggleInstallmentPlansSelect = (plans) => {
    const $installmentPlansSelector = $('#installments-wrapper');
    const $payNowSelector = $installmentPlansSelector.find('.pay-now');
    const $installmentPlanGrouping = $('#installment-plan-grouping-id');

    // Remove all installment plans from DOM, except PAY NOW option
    const installmentsToRemove = document.querySelectorAll('.installment-plan:not(.pay-now)');

    installmentsToRemove.forEach((element) => {
      element.remove();
    });

    $installmentPlanGrouping.val('');

    if (plans.length === 0) {
      $installmentPlansSelector.addClass(HIDDEN);
      stopHandleUiInteractions();
    } else {
      eligibleForInstallments(true);
      addInstallmentPlanOptionsToDom($payNowSelector, plans);
      $payNowSelector.find('input').prop('checked', true);
      $installmentPlansSelector.removeClass(HIDDEN);
      $installmentPlanGrouping.val(plans[0].grouping_id);
      handleUiInteractions();
    }

    animateContainer();
  };

  // Resets the installment selection state
  const reset = () => {
    toggleInstallmentPlansSelect([]);
    eligibleForInstallments(false);
  };

  // Toggles the visibility of the installment loader
  const toggleInstallmentLoader = (show) => {
    const $loader = $('#installments-loader');

    if (show) {
      $loader.removeClass(HIDDEN);
    } else {
      $loader.addClass(HIDDEN);
    }
    animateContainer();
  };

  // Fetches installment plans via an AJAX request
  const getInstallmentPlans = (payload) => {
    const wpf_unique_id = window.location.pathname.split('/').pop();

    toggleInstallmentLoader(true);
    $.ajax({
      type: 'POST',
      url: `/v1/installments/plans/${wpf_unique_id}`,
      data: payload,
      success: (data) =>
        toggleInstallmentPlansSelect(data.plans ?? [])
    }).always(() => {
      toggleInstallmentLoader(false);
    });
  };

  // Handles changes to the token value
  const handleTokenChanged = (token_id) => {
    reset();

    if (token_id) {
      getInstallmentPlans({ token_id });
    }
  };

  // Monitors token changes for handling installment plans
  const handleTokenization = () => {
    if (exists($('.tokens-container'))) {
      $('input[name=token_id]').change(function() {
        handleTokenChanged(this.value);
      });
    }
  };

  // Handles changes to card values and triggers installment validation
  const handleValuesChanged = () => {
    if (!terminalData?.supports_installments) {
      reset();

      return;
    }

    const cardNumber = $('#wpf_form_card_number').val().replace(/\s/g, '');

    clearTimeout(userInputTimeout);

    if (typeof cardNumber === 'undefined') {
      reset();

      return;
    }

    userInputTimeout = setTimeout(() => {
      eligibleForInstallments(false);
      if (isCardNumberValid(cardNumber)) {
        getInstallmentPlans({ card_number: cardNumber });
      } else {
        reset();
      }
    }, USER_INPUT_DELAY);
  };

  // Appends card logo to UI if supported by terminal
  const handleCardLogoAppend = () => {
    if (terminalData.supports_installments) {
      SUPPORT_CARD_BRANDS.forEach((cardBrand) => {
        const installmentLogo = cardBrandsLogoMap.find((logo) => logo.cardBrand === cardBrand);

        if (!exists($(`#${installmentLogo.id}`))) {
          const logo = $('<span/>', {
            id: installmentLogo.id
          });
          const $cardLogo = $(`.accepted-cards .card-logo[data-brand="${cardBrand}"]`);

          $cardLogo.closest('li').addClass('with-installments');
          $cardLogo.append(logo);
        }
      });
    } else {
      SUPPORT_CARD_BRANDS.forEach((cardBrand) => $(`#${cardBrand}`).remove());
    }
  };

  // Initializes the module with terminal data
  const setup = (data) => {
    terminalData = data;
    handleCardLogoAppend();
  };

  // Listens for card input changes to trigger installment checks
  const listenForCardInputChange = () => {
    $('input[name*=card_number]')
      .on('keyup', (event) => {
        if (event.originalEvent.isTrusted) {
          handleValuesChanged();
        }
      });
    handleTokenization();
    handleUiInteractions();
  };

  return {
    setup,
    listenForCardInputChange
  };
})();

export const { setup, listenForCardInputChange } = Installments;

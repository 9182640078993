import { addSupportedCountries } from '@app/features/supported_countries';
import { changeInputStateFor } from '@app/lib/utility/change_input_state';
import { exists } from '@app/lib/utility/exists';
import financialServicesBins from '@app/features/financial_services_bins';
import { handleBoletoDocumentIdVisibility } from '@app/features/boleto_fields';
import { handleConsumerReference } from '@app/features/consumer_reference_field';
import { handleDocumentIdType } from '@app/features/handle_document_id_type';
import { handleNeosurfFieldsVisibility } from '@app/features/neosurf_fields';
import { handleOnlineBankingBankCode } from '@app/features/online_banking';
import { handleSofortIbanFieldVisibility } from '@app/features/sofort_fields';
import { handleTokenization } from '@app/features/handle_tokenization';
import { setup as installmentsSetup } from '@app/features/installments';
import { onlyNumbersFormatter } from 'genesis_wpf_js';
import { showRequiredFields } from '@app/features/required_fields';

export let REQUIRED_FIELDS = false;

const elements_to_show_for_payout = [
  '.payout-submit',
  '.payout-title'
].join(', ');

const elements_to_hide_for_payout = [
  '.payment-title',
  '.or',
  '.merchant-information',
  '.submit-later',
  '.payment-submit',
  '.pay-later-information'
].join(', ');

const elements_to_show_for_acc_verification = [
  '.payout-submit',
  '.account-verification-title'
].join(', ');

const elements_to_hide_for_acc_verification = [
  '.payment-title',
  '.amount',
  '.or',
  '.submit-later',
  '.payment-submit',
  '.pay-later-information'
].join(', ');

export function animateTransactionTypeContainer() {
  var transactionTypeContainer = $('#transaction-types-form-container');

  transactionTypeContainer.height($('.transaction-type-form:not(.hidden)').innerHeight() || 0);
}

export function listenForResize() {
  $(window).on('resize', function() {
    animateTransactionTypeContainer();
  });
}

function cacheRequiredFieldsOnce(data) {
  if (!REQUIRED_FIELDS) {
    REQUIRED_FIELDS = data.required_fields;
  }
}

function showPayoutContent() {
  if(exists($(elements_to_show_for_payout))) {
    $(elements_to_show_for_payout).show();
  }
  if(exists($(elements_to_hide_for_payout))) {
    $(elements_to_hide_for_payout).hide();
  }
}

function showAccountVerificationContent() {
  if(exists($(elements_to_show_for_acc_verification))) {
    $(elements_to_show_for_acc_verification).show();
  }
  if(exists($(elements_to_hide_for_acc_verification))) {
    $(elements_to_hide_for_acc_verification).hide();
  }
}

function hidePayoutContent() {
  if(exists($(elements_to_show_for_payout))) {
    $(elements_to_show_for_payout).hide();
  }
  if(exists($(elements_to_hide_for_payout))) {
    $(elements_to_hide_for_payout).show();
  }
}

function hideAccountVerificationContent() {
  if(exists($(elements_to_show_for_acc_verification))) {
    $(elements_to_show_for_acc_verification).hide();
  }
  if(exists($(elements_to_hide_for_acc_verification))) {
    $(elements_to_hide_for_acc_verification).show();
  }
}

function handleFormContent(transactionType) {
  if(transactionType.includes('_payout')) {
    hideAccountVerificationContent();
    showPayoutContent();
  } else if(transactionType === 'account_verification') {
    hidePayoutContent();
    showAccountVerificationContent();
  } else {
    hidePayoutContent();
    hideAccountVerificationContent();
  }
}

function clearHiddenTypesInputValues(type) {
  $('.' + type + '-form.hidden input[type="text"]').val('');
  $('.' + type + '-form.hidden select option').filter('selected').removeAttr('selected');
}

function showPaymentTypeFormFor(paymentType) {
  $('.payment-type-form').addClass('hidden');
  changeInputStateFor($('.payment-type-form'), true);

  $('.payment-type-form.' + paymentType).removeClass('hidden');
  animateTransactionTypeContainer();
  clearHiddenTypesInputValues('payment-type');
  changeInputStateFor($('.payment-type-form.' + paymentType), false);
}

function setTokenCardDependables(data) {
  if ($.isEmptyObject(data)) {
    return;
  }

  $.each(JSON.parse(data), function(tokenId, cardDependables) {
    $('.token-' + tokenId).data('card-dependables', cardDependables);
  });

  handleTokenization();
}

export function showTransactionTypeFormFor(transactionType) {
  $('.transaction-type-form').addClass('hidden');
  $('.payment-type-form').addClass('hidden');
  changeInputStateFor($('.transaction-type-form'), true);

  var wpf_unique_id = window.location.pathname.split('/').pop();

  $.ajax({
    type: 'GET',
    url: '/payment/terminal_for/' + wpf_unique_id,
    data: {
      selected_transaction_type: transactionType,
      bins_requested: financialServicesBins.isIncomplete()
    },
    success: function(data) {
      financialServicesBins.ukVisaBins = data.uk_bins.visa;
      financialServicesBins.ukDebitMastercardBins = data.uk_bins.master_maestro;

      cacheRequiredFieldsOnce(data);
      showRequiredFields(data);
      setTokenCardDependables(data.card_dependable);
      handleConsumerReference(data.gateway);
      handleOnlineBankingBankCode(data.gateway);
      handleSofortIbanFieldVisibility(data.gateway);
      handleNeosurfFieldsVisibility(data.gateway);
      handleBoletoDocumentIdVisibility(data.gateway, transactionType);
      animateTransactionTypeContainer();
      handleDocumentIdType(transactionType);
      installmentsSetup(data);
    }
  });

  handleFormContent(transactionType);
  $('.transaction-type-form.' + transactionType).removeClass('hidden');
  animateTransactionTypeContainer();
  clearHiddenTypesInputValues('transaction-type');
  changeInputStateFor($('.transaction-type-form.' + transactionType), false);
  changeInputStateFor($('.transaction-type-form.' + transactionType +
                        ' .payment-type-form'), true);

}

export function handlePaymentTypeSelect() {
  $('.transaction-type-form.with-payment-types input[type="radio"]')
    .filter(':checked').each(function() {
      showPaymentTypeFormFor($(this).val());
    });

  $('.transaction-type-form.with-payment-types input[type="radio"]').on('change', function() {
    var paymentType = $(this).val();

    $('.transaction-type-form.with-payment-types input[type="radio"]')
      .filter(':checked').prop('checked', false);
    $(this).prop('checked', true);

    $('.error.payment-types').addClass('hidden');
    showPaymentTypeFormFor(paymentType);
  });
}

export function handleBankCodesSelect() {
  $('.bank-codes input[type="radio"]').on('click', function() {
    const $selectedBank = $(this);

    $selectedBank.prop('checked', true);
    $('select#wpf_form_bank_code').val($selectedBank.val()).trigger('chosen:updated');
  });
}

export function handlePaymentMethodCategorySelect() {
  $('.payment-method-categories input[type="radio"]').on('change', function() {
    $('.error.payment-method-categories').addClass('hidden');
  });
}

export function handleTransactionTypeSelect() {
  $('.transaction-types input[type="radio"]').filter(':checked').each(function() {
    showTransactionTypeFormFor($(this).val());
    addSupportedCountries($(this).val());
  });

  $('.transaction-types input[type="radio"]').on('change', function() {
    var transactionType = $(this).val();

    addSupportedCountries(transactionType);

    $('input[type="radio"]').filter(':checked').not('[name="token_id"]').prop('checked', false);
    $(this).prop('checked', true);

    $('.error.transaction-types').addClass('hidden');
    showTransactionTypeFormFor(transactionType);
  });

  var paymentTypeButton = $('.payment-types input[type="radio"]');

  paymentTypeButton.on('change', function() {
    var transactionType = $('.transaction-types input[type="radio"]').filter(':checked').val();

    paymentTypeButton.filter(':checked').each(function() {
      var paymentType = $(this).val();

      addSupportedCountries(transactionType, paymentType);
    });
  });
}

export function handleAuthCodeInput() {
  $('#wpf_form_auth_code').on('input', function() {
    const rawValue = $(this).val();
    const formattedValue = onlyNumbersFormatter(rawValue);

    $(this).val(formattedValue);
  });
}
